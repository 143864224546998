<template>
  <div class="bg">
    <div class="logo-box">
      <img src="@/assets/images/logo.png" alt="" />
      <span class="title">智慧小区后台管理系统</span>
    </div>
    <div class="login-box">
      <a-row>
        <!-- <a-col :span="12" class="box-a">
          <img src="@/assets/images/logo.png" alt="" />
        </a-col> -->
        <!-- <a-col :span="1" class="box-cen"></a-col> -->
        <!-- 登录 -->
          <a-col class="box-b" v-if="status === 1">
            <a-tabs v-model="activeName">
              <a-tab-pane key="1" tab="密码登录"></a-tab-pane>
              <a-tab-pane key="2" tab="验证码登录"> </a-tab-pane>
            </a-tabs>
            <div class="login-input" v-if="activeName === '1'">
              <a-cascader
                :options="cityList"
                placeholder="请选择城市"
                v-model="cityvalue"
                :show-search="{ filter }"
                @change="onChange"
                :field-names="{
                  label: 'name',
                  value: 'id',
                  children: 'cityList',
                }"
              />
              <span class="form">
                <a-select
                  v-model="communityCode"
                  notFoundContent="该城市无可选择小区"
                  :disabled="cityvalue.length === 0"
                  placeholder="请选择小区"
                  @change="communityChange"
                >
                  <a-select-option
                    v-for="(item) in communityList"
                    :key="item.id"
                    :value="item.code"
                    >{{ item.name }}</a-select-option
                  >
                </a-select>
                <a-input v-model="pwdLogin.username" placeholder="请输入手机号码"></a-input>
                <a-input v-model="pwdLogin.password" type="password" placeholder="请输入密码"></a-input>
                <!-- <a-row>
                  <a-col :span="12" style="margin-left: 23px"
                    ><a-checkbox></a-checkbox>&nbsp;&nbsp;三天之内免登陆</a-col
                  >
                </a-row> -->
              </span>
              <a-button
                type="primary"
                @click="passwordLogin"
                style="
                  width: 90%;
                  margin-left: 30px;
                  margin-top: 20px;
                  height:38px;
                  margin-bottom: 10px;
                "
              >
                登录
              </a-button>
              <a-row style="margin-top: 20px; margin-left: 23px">
                <a-col :span="24">
                  <a-checkbox v-model="isChecked"></a-checkbox>&nbsp;&nbsp;我已同意
                  <a href="/#/rule" target="tar">《用户协议》</a>
                  <a href="/#/yszc" target="tar">《隐私政策》</a>
                </a-col>
                <a-col :span="24" style="margin-top:16px">
                  <span class="logintext" @click="status = 3">忘记密码</span
                  >&nbsp;&nbsp;&nbsp;&nbsp;<span
                    class="logintext"
                    @click="status = 2"
                    >免费注册</span
                  >
                </a-col>
              </a-row>
            </div>

            <div class="login-input" v-else-if="activeName === '2'">
              <a-cascader
                :options="cityList"
                placeholder="请选择城市"
                v-model="cityvalue"
                :show-search="{ filter }"
                @change="onChange"
                :field-names="{
                  label: 'name',
                  value: 'id',
                  children: 'cityList',
                }"
              />

              <span class="form">
                <a-select
                  v-model="communityCode"
                  notFoundContent="该城市无可选择小区"
                  :disabled="cityvalue.length === 0"
                  placeholder="请选择小区"
                  @change="communityChange"
                >
                  <a-select-option
                    v-for="item in communityList"
                    :key="item.id"
                    :value="item.code"
                    >{{ item.name }}</a-select-option
                  >
                </a-select>
                <a-input
                  placeholder="请输入手机号码"
                  v-model="form.tel"
                ></a-input>
                <a-input
                  placeholder="请输入验证码"
                  v-model="form.code"
                  style="width: 60%"
                ></a-input>
              </span>
              <a-button type="primary" @click="getCode" :disabled="inCodeGap">
                {{inCodeGap == false ? '获取验证码' : codeGap+'s后再次获取'}}
              </a-button>
              <!-- <a-row>
                <a-col :span="12" style="margin-left: 23px"
                  ><a-checkbox></a-checkbox>&nbsp;&nbsp;三天之内免登陆</a-col
                >
              </a-row> -->
              <a-button
                type="primary"
                @click="login"
                style="
                  width: 90%;
                  margin-left: 30px;
                  margin-top: 20px;
                  height:38px;
                  margin-bottom: 10px;
                "
              >
                登录
              </a-button>
              <a-row style="margin-top: 20px; margin-left: 23px">
                <a-col :span="22">
                  <a-checkbox v-model="isChecked"></a-checkbox>&nbsp;&nbsp;我已同意
                  <a href="/#/rule" target="tar">《用户协议》</a>
                  <a href="/#/yszc" target="tar">《隐私政策》</a>
                </a-col>
                <a-col :span="2">
                  <!-- <span class="logintext" @click="status = 3">忘记密码</span
                  >&nbsp;&nbsp;&nbsp;&nbsp;<span
                    class="logintext"
                    @click="status = 2"
                    >免费注册</span
                  > -->
                </a-col>
              </a-row>
            </div>
          </a-col>
        <!-- 注册 -->
          <a-col class="box-b" v-if="status === 2">
            <div class="cardTitle">新建账号</div>
            <a-form-model
              ref="ruleForm"
              :model="form"
              :rules="rules"
              layout="vertical"
            >
              <a-form-model-item ref="tel" label="手机号码" prop="tel">
                <a-input
                  v-model="form.tel"
                  style="width: 100%"
                  placeholder="请输入手机号"
                  @blur="
                    () => {
                      $refs.tel.onFieldBlur();
                    }
                  "
                />
                <a-row style="margin-top: 20px">
                  <a-col :span="16" style="margin-right: 10px">
                    <a-input
                      style="width: 100%"
                      v-model="form.verificationCode"
                      placeholder="请输入验证码"
                    />
                  </a-col>
                  <a-col :span="6">
                    <a-button type="primary" @click="getCode" :disabled="inCodeGap">
                      {{inCodeGap == false ? '获取验证码' : codeGap+'s后再次获取'}}
                    </a-button>
                  </a-col>
                </a-row>
              </a-form-model-item>
              <a-form-model-item label="设置密码" prop="psw">
                <a-input placeholder="请输入密码" />
              </a-form-model-item>
              <a-form-model-item label="确认密码" prop="confirmPsw">
                <a-input placeholder="请再次输入密码" />
              </a-form-model-item>
              <a-form-model-item>
                <a-button type="primary" style="width: 100%"> 确定 </a-button>
              </a-form-model-item>
              <a-form-model-item>
                <span>已有账号？</span
                ><span class="logintext" @click="status = 1">登录</span>
              </a-form-model-item>
            </a-form-model>
          </a-col>
        <!-- 找回密码 -->
        <a-col class="box-b" v-if="status === 3">
          <div class="cardTitle">找回密码</div>
          <a-form-model
            ref="ruleForm"
            :model="form"
            :rules="rules"
            layout="vertical"
          >
            <a-form-model-item ref="tel" label="手机号码" prop="tel">
              <a-input
                v-model="form.tel"
                style="width: 100%"
                placeholder="请输入手机号"
                @blur="
                  () => {
                    $refs.tel.onFieldBlur();
                  }
                "
              />
              <a-row style="margin-top: 20px">
                <a-col :span="16" style="margin-right: 10px">
                  <a-input
                    style="width: 100%"
                    v-model="form.verificationCode"
                    placeholder="请输入验证码"
                  />
                </a-col>
                <a-col :span="6">
                  <a-button type="primary" @click="getCode" :disabled="inCodeGap">
                    {{inCodeGap == false ? '获取验证码' : codeGap+'s后再次获取'}}
                  </a-button>
                </a-col>
              </a-row>
            </a-form-model-item>
            <a-form-model-item label="设置密码" prop="psw">
              <a-input placeholder="请输入密码" />
            </a-form-model-item>
            <a-form-model-item label="确认密码" prop="confirmPsw">
              <a-input placeholder="请再次输入密码" />
            </a-form-model-item>
            <a-form-model-item>
              <a-button type="primary" style="width: 100%"> 确定 </a-button>
            </a-form-model-item>
            <a-form-model-item>
              <span>已有账号？</span
              ><span class="logintext" @click="status = 1">登录</span>
            </a-form-model-item>
          </a-form-model>
        </a-col>
      </a-row>
    </div>
  </div>
</template>

<script>
import { loginTel, loginPWD, sendTelCode, userInfo } from "../../api/public/login";
export default {
	name: "Login",
	data() {
		return {
			status: 1,
			activeName: "2",
			form: {
				tel: "",
				code: "",
			},
      pwdLogin: {
        username: '',
        password: '',
      },  
      isChecked: false,
      communityId: undefined,
			communityCode: undefined,
			rules: {
				tel: [
					{
						required: true,
						message: "请输入手机号",
						trigger: "blur",
					},
					{
						min: 11,
						max: 11,
						message: "姓名长度必须为 11 位",
						trigger: "blur",
					},
				],
				name: [
					{
						required: true,
						message: "请输入姓名",
						trigger: "blur",
					},
					{
						min: 3,
						max: 5,
						message: "姓名长度必须为 3 至 5位",
						trigger: "blur",
					},
				],
			},
			cityList: [], //城市列表
			cityvalue: [], //省市区级联绑定值
			communityList: [], //小区列表
      inCodeGap: false,
      codeGap: 60,
		};
	},
	created() {
    if(this.$route.query.token && this.$route.query.code) {
      this.setToken(this.$route.query.token);
      this.setCommunityCode(this.$route.query.code);
      userInfo().then(result => {
        this.setUserInfo(result.data);
        this.$router.push("/");
      })
    }
		this.keyupEnter();
		this.getAllCity();
	},
	watch: {
		communityCode: {
			handler(value) {
				this.setCommunityCode(value);
			},
		},
	},
	methods: {
		getAllCity() {
			this.$axios({
				method: "get",
				url: process.env.VUE_APP_URL + "manage/city/allCity", //【api不需要communityCode】
			})
				.then((response) => {
					this.cityList = response.data.data;
					this.setCity(response.data.data);
				})
				.catch((error) => {
					console.log(error); //请求失败返回的数据
				});
		},
		getCommunity(cityId) {
			this.$axios({
				method: "get",
				url: process.env.VUE_APP_URL + "manage/community/findAll", //【api不需要communityCode】
				params: {
					cityId: cityId,
				},
			})
				.then((response) => {
					this.communityList = response.data.data;
				})
				.catch((error) => {
					console.log(error); //请求失败返回的数据
				});
		},
		// 选择城市后获取该城市小区
		onChange(data) {
			this.getCommunity(data[2]);
			this.communityCode = undefined;
		},
		// 级联筛选
		filter(inputValue, path) {
			return path.some(
				(cityList) =>
					cityList.name.toLowerCase().indexOf(inputValue.toLowerCase()) > -1
			);
		},
		// 绑定enter事件
		keyupEnter() {
			document.onkeydown = (e) => {
				if (e.keyCode === 13 && e.target.baseURI.match(/loginTel/)) {
					//回车后执行搜索方法
					this.login();
				}
			};
		},
		login() {
			if (this.communityCode === undefined) {
				this.$message.error("先选择小区");
				return;
			};
      if (this.isChecked == false) {
        this.$message.error("请阅读并同意《用户协议》");
				return;
      };
			loginTel(this.form).then((res) => {
				if (res.code === 200) {
					this.$message.success(res.msg);
					// 存储token
					this.setToken(res.data);
          userInfo().then(result => {
            this.setUserInfo(result.data);
            this.$router.push("/");
          })
				} else {
					this.$message.error(res.msg);
				}
			});
		},
    passwordLogin() {
      if (this.communityCode === undefined) {
				this.$message.error("先选择小区");
				return;
			};
      if (this.isChecked == false) {
        this.$message.error("请阅读并同意《用户协议》");
				return;
      };
      let obj = Object.assign(this.pwdLogin, {communityId: this.communityId})
      loginPWD(obj).then((res) => {
				if (res.code === 200) {
					this.$message.success(res.msg);
					// 存储token
					this.setToken(res.data);
          userInfo().then(result => {
            this.setUserInfo(result.data);
            this.$router.push("/");
          })
				} else {
					this.$message.error(res.msg);
				}
			});
    },
		// 获取验证码
		getCode() {
			if (this.communityCode === undefined) {
				this.$message.error("先选择小区");
				return;
			}
      let num = setInterval(() => {
        this.inCodeGap = true;
        this.codeGap -= 1;
        if(this.codeGap < 1) {
          clearInterval(num);
          if(this.codeGap < 1) {
            this.inCodeGap = false;
            this.codeGap = 60;
          }
        }
      },1000)
			sendTelCode(this.form).then((res) => {
				if (res) {
					this.$message.success(res.msg);
				}
			});
		},
		onSubmit() {
			this.$refs.ruleForm.validate((valid) => {
				if (valid) {
					alert("submit!");
				} else {
					console.log("error submit!!");
					return false;
				}
			});
		},
		// 重置
		resetForm() {
			this.$refs.ruleForm.resetFields();
		},
    //改变小区获取id
    communityChange(value, option) {
      this.communityId = option.key
    }
	},
};
</script>

<style lang="less" scoped>
.bg {
  background-image: url(../../assets/images/loginBg.png);
  height: 100vh;
  background-size: cover;
}
.logo-box {
  text-align: left;
  background: #ffffff;
  width: 100%;
  height: 93px;
  line-height: 93px;
  margin-bottom: 93px;
  img {
    width: 51px;
    height: 51px;
    background: #ffffff;
    margin-left: 42px;
    margin-bottom: 13px;
  }
  .title {
    font-size: 28px;
    color: #333333;
    font-weight: 500;
    margin-left: 20px;
    line-height: 93px;
  }
}
.login-box {
  background: #ffffff;
  height: 500px;
  width: 489px;
  margin: auto;
}
.box-a {
  height: 500px;
  padding: 10px;
}
.box-cen {
  background: #e5e5e5;
  height: 500px;
}
.box-b {
  height: 500px;
  text-align: left;
  padding: 30px;
}
.cardTitle {
  margin-left: -10px;
  font-size: 18px;
  color: #000000;
  font-weight: 500;
  margin-bottom: 40px;
}
.logintext {
  color: #205fbd;
  cursor: pointer;
}
/deep/.ant-form-item {
  margin-top: -10px;
}
.login-input .form {
  text-align: left;
  margin-top: 20px;
  /deep/.ant-input {
    border: 0px;
    border-bottom: 1px solid #f4eded;
    border-radius: 0;
    width: 90%;
    margin-left: 25px;
    margin-bottom: 20px;
  }
  /deep/.ant-select {
    border: 0px;
    border-bottom: 1px solid #f4eded;
    border-radius: 0;
    width: 90%;
    margin-left: 25px;
    margin-top: 15px;
    margin-bottom: 15px;
  }
}
/deep/.ant-cascader-picker {
  text-align: left;
  margin-top: 20px;
  margin-left: 25px;
  width: 90%;
}
</style>
